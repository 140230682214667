import React, { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { CiLight } from "react-icons/ci";
import { useDispatch } from "react-redux";
import axios from "axios";
import { recieveData } from "../../features/panelSlice";
import reducerFunction from "../../features/extraReducersFunction";
import ConfirmationModal from "./ConfrimationModal";

const EditUser = ({ editData, showHideViewFuncEdit }) => {
  const { userDetails, driverBankDetails, driverVehicleDetails } = editData;
  const [btnText, setBtnText] = useState("Basic");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [modalAction, setModalAction] = useState({});

  // const [formData, setFormData] = useState({
  // Account Number: "",
  // active: "",
  // address: "",
  // carHealthCard: "",
  // city: "",
  // color: "",
  // createdAt: "",
  // driverId: "",
  // IFSC Code: "",
  // email: "",
  // fcmToken: "",
  // fullname: "",
  // insurance: "",
  // lat: "",
  // long: "",
  // numberOfSeat: "",
  // numberPlate: "",
  // otp: "",
  // otpCreatedAt: ,
  // ownerReferCode: "",
  // password: "",
  // phone: "",
  // postalCode: "",
  // puc: "",
  // registrationNumber: "",
  // type: "",
  // updatedAt: "",
  // __v: "",
  // _id: ""
  // });
  // const [formData, setFormData] = useState({ ...userDetails, ...driverBankDetails, ...driverVehicleDetails });
  const [formData, setFormData] = useState({
    ...editData,
  });
  const [selectedImage, setSelectedImage] = useState({
    aadharCard: null,
    drivingLicense: null,
  });

  const dispatch = useDispatch();
  const handleChange = (e) => {
    const { name, value, files } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (btnText === "Bank") {
    //   updateBankDetails();
    // }
    // else {
    //   console.log("Form submitted:", formData);
    // }
    if (btnText === "Bank") {
      await updateBankDetails();
    } else {
      await updateUserDetails(); // Add this function for updating user details
    }
    const isFormFilled = Object.values(formData).every(
      (value) => value !== "" && value !== null
    );
    if (isFormFilled) {
      console.log("Form submitted:", formData);
      // setFormData(
      // {
      // Account Number: "",
      // active: "",
      // address: "",
      // carHealthCard: "",
      // city: "",
      // color: "",
      // createdAt: "",
      // driverId: "",
      // IFSC Code: "",
      // email: "",
      // fcmToken: "",
      // fullname: "",
      // insurance: "",
      // lat: "",
      // long: "",
      // make: "",
      // model: "",
      // numberOfSeat: "",
      // numberPlate: "",
      // otp: "",
      // otpCreatedAt: ,
      // ownerReferCode: "",
      // password: "",
      // phone: "",
      // postalCode: "",
      // puc: "",
      // registrationNumber: "",
      // type: "",
      // __v: "",
      // _id: ""
      //     })
    }
    // else {
    //     alert('Please fill in all required fields.');
    // }
  };

  useEffect(() => {
    // setFormData({ ...userDetails, ...driverBankDetails, ...driverVehicleDetails })
    setFormData({ ...editData });
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage({
        ...selectedImage,
        [e.target.name]: URL.createObjectURL(file),
      });
    }
  };

  const handleFormChange = (btnSelect) => {
    setBtnText(btnSelect);
  };
  const baseurl = "https://api.zigcabio.com/"; // Define your base URL here
  const updateBankDetails = async () => {
    await axios
      .put(`${baseurl}admin/api/updateBank/65fd450f7c7ed386e9ebb170`, {
        bankName: formData.Bank,
        accountNumber: formData.Account,
        IFSCCode: formData["IFSC Code"],
        name: formData.name,
      })
      .then((res) => {
        if (res.data.success) {
          alert(res.data.message);
          showHideViewFuncEdit(false);
          dispatch(reducerFunction.getAllUsers());
        }
      })
      .catch((error) => {
        console.error("Error updating bank details:", error);
        alert("An error occurred while updating bank details.");
      });
  };
  const updateUserDetails = async () => {
    await axios
      .put(`${baseurl}admin/users/update/${formData._id}`, {
        fullname: formData.fullname,
        phone: formData.phone,
        email: formData.email,
        address: formData.address,
      })
      .then((res) => {
        if (res.data.success) {
          alert(res.data.message);
          showHideViewFuncEdit(false);
          dispatch(reducerFunction.getAllUsers());
        }
      })
      .catch((error) => {
        console.error("Error updating user details:", error);
        alert("An error occurred while updating user details.");
      });
  };

  const upDateForm = async (data) => {
    // console.log(formData,"form datas");
    console.log(data);
    console.log(data.formData);
    if (data.status === 1) {
      console.log("Approved Request");
      //  dispatch(reducerFunction.ApprovedRequest())
      axios
        .post(`${baseurl}admin/users/approved/${data.formData._id}`)
        .then((res) => {
          if (res.data.success) {
            alert(res.data.message);
            showHideViewFuncEdit(false);
            dispatch(reducerFunction.getAllUsers());
          }
          // console.log("Approved",res)
        });
    }
    if (data.status === 2) {
      //  dispatch(reducerFunction.RejectedRequest())
      axios
        .post(`${baseurl}admin/users/reject/${data.formData._id}`)
        .then((res) => {
          if (res.data.success) {
            alert(res.data.message);
            showHideViewFuncEdit(false);
            dispatch(reducerFunction.getAllUsers());
          }
          // console.log("Rejected",res)
        });
    }
    const userId = data.formData.userid;
  };
  const openConfirmationModal = (action) => {
    setModalAction(action);
    setShowConfirmation(true);
  };

  return (
    <>
      <div className="">
        <div className=" w-[800px] p-4 min-h-[550px] m-auto bg-white absolute right-[20%] top-[-25px] rounded-xl driverForm z-20">
          <h1 className="font-semibold text-[#464650] text-[15px]">
            Edit :- {formData?.fullname}{" "}
          </h1>
          <p
            onClick={() => showHideViewFuncEdit(false)}
            className="relative top-[20px] cursor-pointer float-end right-[30px] hover:bg-red-300 z-10 transform rotate-45 text-2xl p-1 bg-[#8C7FE6] rounded-2xl text-[#E02121]"
          >
            <AiOutlinePlus />
          </p>
          <div className="flex w-[420px] m-auto justify-between mt-3">
            <button
              onClick={() => handleFormChange("Basic")}
              type="button"
              className={`w-[210px] h-[45px] rounded-t-lg  ${
                btnText === "Basic"
                  ? "hover:bg-[#8C7FE6] text-white"
                  : "hover:bg-[#e1e6fa]"
              } ${btnText === "Basic" && "bg-[#8C7FE6] text-white "}`}
            >
              Basic Details
            </button>
            <button
              onClick={() => handleFormChange("Bank")}
              type="button"
              className={`w-[210px] h-[45px] rounded-t-lg  ${
                btnText === "Bank"
                  ? "hover:bg-[#8C7FE6] text-white"
                  : "hover:bg-[#e1e6fa]"
              }  ${btnText === "Bank" && "bg-[#8C7FE6]"}`}
            >
              Bank Details
            </button>
          </div>
          <hr className="w-[420px]   ml-[158px] h-[3px] border-[#8C7FE6] bg-[#8C7FE6]" />
          <form onSubmit={handleSubmit}>
            {btnText === "Basic" ? (
              <>
                {" "}
                <div className="BasicDetails flex flex-col justify-center items-center my-8 gap-4">
                  <div className="flex w-[70%]">
                    <label
                      className="w-[25%] bg-[#8C7FE6] flex items-center pl-3 rounded-l-lg text-white"
                      htmlFor="fullname"
                    >
                      User Name :
                    </label>
                    <input
                      type="text"
                      id="fullname"
                      name="fullname"
                      value={formData?.fullname ? formData?.fullname : ""}
                      onChange={handleChange}
                      required
                      placeholder="User Name"
                    />
                  </div>
                  <div className="flex w-[70%]">
                    <label
                      className="w-[25%] bg-[#8C7FE6] flex items-center pl-3 rounded-l-lg text-white"
                      htmlFor="phone"
                    >
                      Phone # :
                    </label>
                    <input
                      type="text"
                      id="phone"
                      name="phone"
                      value={formData?.phone ? formData?.phone : ""}
                      onChange={handleChange}
                      required
                      placeholder="Phone Number"
                    />
                  </div>
                  <div className="flex w-[70%]">
                    <label
                      className="w-[25%] bg-[#8C7FE6] flex items-center pl-3 rounded-l-lg text-white"
                      htmlFor="address"
                    >
                      Address :
                    </label>
                    <input
                      type="text"
                      name="address"
                      id="address"
                      value={formData?.address ? formData?.address : ""}
                      onChange={handleChange}
                      required
                      placeholder="Address"
                    />
                  </div>
                  <div className="flex w-[70%]">
                    <label
                      className="w-[25%] bg-[#8C7FE6] flex items-center pl-3 rounded-l-lg text-white"
                      htmlFor="phoneNumber"
                    >
                      E-mail :
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      value={formData?.email ? formData?.email : ""}
                      onChange={handleChange}
                      required
                      placeholder="Email ID"
                    />
                  </div>
                </div>
                <div className="w-full flex justify-center items-center my-4">
                  <button
                    onClick={() => handleFormChange("Bank")}
                    className="py-2 px-10 rounded-3xl border-2 hover:bg-[#c6d0ff] hover:text-black  bg-[#8C7FE6] active:bg-transparent text-white"
                    type="button"
                  >
                    Next
                  </button>
                </div>
              </>
            ) : btnText === "Bank" ? (
              <>
                <div className="BasicDetails flex flex-col justify-center items-center my-8 gap-4">
                  <div className="flex w-[70%]">
                    <label
                      className="w-[25%] bg-[#8C7FE6] flex items-center pl-3 rounded-l-lg text-white"
                      htmlFor="Bank"
                    >
                      Bank Name :
                    </label>
                    <input
                      type="text"
                      name="Bank"
                      id="Bank"
                      value={formData?.Bank || ""}
                      onChange={handleChange}
                      required
                      placeholder="Bank Name"
                    />
                  </div>
                  <div className="flex w-[70%]">
                    <label
                      className="w-[25%] bg-[#8C7FE6] flex items-center pl-3 rounded-l-lg text-white"
                      htmlFor="Account"
                    >
                      Account Number:
                    </label>
                    <input
                      type="text"
                      name="Account"
                      id="Account"
                      value={formData?.Account || ""}
                      onChange={handleChange}
                      required
                      placeholder="Account Number"
                    />
                  </div>
                  <div className="flex w-[70%]">
                    <label
                      className="w-[25%] bg-[#8C7FE6] flex items-center pl-3 rounded-l-lg text-white"
                      htmlFor="IFSC Code"
                    >
                      IFSC Code :
                    </label>
                    <input
                      type="text"
                      name="IFSC Code"
                      id="IFSC Code"
                      value={formData?.["IFSC Code"] || ""}
                      onChange={handleChange}
                      required
                      placeholder="IFSC Code"
                    />
                  </div>
                  <div className="flex w-[70%]">
                    <label
                      className="w-[25%] bg-[#8C7FE6] flex items-center pl-3 rounded-l-lg text-white"
                      htmlFor="name"
                    >
                      Name :
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      value={formData?.name || ""}
                      onChange={handleChange}
                      required
                      placeholder="Name"
                    />
                  </div>
                </div>
                <div className="w-full flex justify-center items-center my-10 gap-3">
                  {formData.active === 0 || formData.active === 1 ? (
                    <button
                      className="py-2 px-10 rounded-3xl border-2 hover:bg-red-500 hover:text-black bg-[#D42424] active:bg-transparent text-white"
                      type="button"
                      onClick={() =>
                        openConfirmationModal({
                          type: "Reject",
                          status: 2,
                          formData,
                        })
                      }
                    >
                      Reject
                    </button>
                  ) : null}
                  {formData.active === 0 || formData.active === 2 ? (
                    <button
                      className="py-2 px-10 rounded-3xl border-2 hover:bg-[#8ab67d] hover:text-black bg-[#56874A] active:bg-transparent text-white"
                      type="submit"
                      onClick={() =>
                        openConfirmationModal({
                          type: "Approve",
                          status: 1,
                          formData,
                        })
                      }
                    >
                      Approved
                    </button>
                  ) : null}
                  <button
                    className="py-2 px-10 rounded-3xl border-2 hover:bg-[#9f95e0] hover:text-black  bg-[#8C7FE6] active:bg-transparent text-white"
                    type="button"
                    onClick={() => {
                      showHideViewFuncEdit(false);
                      updateUserDetails();
                      updateBankDetails();
                    }}
                  >
                    Update
                  </button>
                  {/* <button
                                        className='py-2 px-10 rounded-3xl border-2 hover:bg-[#8ab67d] hover:text-black bg-[#56874A] active:bg-transparent text-white'
                                        type='submit'
                                        onClick={() => dispatch(approve({ id: formData?.id, satus: true }))}
                                    >
                                        Approve Request
                                    </button> */}
                </div>{" "}
              </>
            ) : (
              {
                /* <div className="w-full flex justify-center items-center my-4">
                  <button
                    onClick={() => handleFormChange("VehicalH")}
                    className="py-2 px-10 rounded-3xl border-2 hover:bg-[#c6d0ff] hover:text-black  bg-[#8C7FE6] active:bg-transparent text-white"
                    type="button"
                  >
                    Next
                  </button>
                </div>{" "} */
              }
            )}
          </form>
        </div>
      </div>
      {showConfirmation && (
        <ConfirmationModal
          actionText={`Are you sure you want to ${modalAction.type} this user ?`}
          handleAction={() => {
            upDateForm(modalAction);
            setShowConfirmation(false);
          }}
          handleClose={() => setShowConfirmation(false)}
        />
      )}
    </>
  );
};

export default EditUser;
