import React from 'react'

const Footer = () => {
    return (
        <div className='fixed bottom-0'>
            <h2 className=' text-xs  mx-2'>Designed and Developed by : Visom6 Technology </h2>
        </div>
    )
}

export default Footer



// designed and developed by visom6technology