import React from "react";

const VehicalForm = ({ rates, handleRateChange, charge }) => {
  // Safely accessing the data object with a fallback to an empty object
  const data = rates[`${charge === "Day" ? "dayCharge" : "nightCharge"}`] || {};

  return (
    <div className="flex rateDiv w-full justify-between items-center ">
      <>
        <div className="w-[40%] flex flex-col gap-2">
          <p>1st Km Rate</p>
          <input
            type="text"
            required
            placeholder="21/Km"
            value={data.firstKm || ""}
            onChange={(e) => handleRateChange("firstKmRate", e.target.value)}
          />
        </div>
        <div className="w-[40%] flex flex-col gap-2">
          <p>2nd Km Rate</p>
          <input
            type="text"
            required
            placeholder="11/Km"
            value={data.secondKm || ""}
            onChange={(e) => handleRateChange("secondKmRate", e.target.value)}
          />
        </div>
        <div className="w-[40%] flex flex-col gap-2">
          <p>Waiting charges</p>
          <input
            type="text"
            required
            placeholder="3/min"
            value={data.waitingCharge || ""}
            onChange={(e) => handleRateChange("waitingCharge", e.target.value)}
          />
        </div>
        <div className="min-w-[40%] flex flex-col gap-2">
          <p>Cancellation Charges</p>
          <input
            type="text"
            required
            placeholder="5/Cancel"
            value={data.cancellationCharge || ""}
            onChange={(e) =>
              handleRateChange("cancellationCharge", e.target.value)
            }
          />
        </div>
      </>
    </div>
  );
};

export default VehicalForm;
