import axios from "axios";
// const BASEURL = 'https://api.zigcabio.com/admin/api/'
// const url = process.env.REACT_APP_BASE_URL || 'https://api.zigcabio.com/';
const url = "https://api.zigcabio.com/";
const getAllDriver = async () => {
  let getAllDriverData = await axios.get(`${url}admin/api/getAllDriver`);
  // console.log(getAllUsersData.data.data)
  return await getAllDriverData.data.data;
};
const ApprovedRequest = async (id) => {
  let ApprovedRequest = await axios.post(`${url}admin/drivers/approved/${id}`);
  // console.log(ApprovedRequest.data.data)
  //  console.log("Approved",ApprovedRequest);
  return await ApprovedRequest.data.data;
};
const RejectedRequest = async (id) => {
  let RejectedRequest = await axios.post(`${url}admin/drivers/reject/${id}`);
  // console.log(RejectedRequest.data.data)
  // console.log("Tester");
  return await RejectedRequest.data.data;
};
const getAllUsers = async () => {
  let getAllUsersData = await axios.get(`${url}admin/api/getAllUsers`);
  // console.log(getAllUsersData.data.data)
  return await getAllUsersData.data.data;
};
const ApprovedRequestUser = async (id) => {
  let ApprovedRequestUser = await axios.post(
    `${url}admin/users/approved/${id}`
  );
  // console.log(ApprovedRequestUser.data.data)
  //  console.log("Approved",ApprovedRequestUser);
  return await ApprovedRequestUser.data.data;
};
const RejectedRequestUser = async (id) => {
  let RejectedRequestUser = await axios.post(`${url}admin/users/reject/${id}`);
  // console.log(RejectedRequestUser.data.data)
  // console.log("Tester");
  return await RejectedRequestUser.data.data;
};

const blockDriver = async (id) => {
  let blockDriver = await axios.post(`${url}admin/drivers/block/${id}`);
  return await blockDriver.data.data;
};

const deleteDriver = async (id) => {
  let deleteDriver = await axios.delete(`${url}admin/drivers/delete/${id}`);
  return await deleteDriver.data.data;
};
// Function to block a user
const blockUser = async (id) => {
  try {
    const response = await axios.post(`${url}admin/users/block/${id}`);
    return response.data.data;
  } catch (error) {
    console.error("Error blocking user:", error);
    throw error;
  }
};
// Function to delete a user
const deleteUser = async (id) => {
  try {
    const response = await axios.delete(`${url}admin/users/delete/${id}`);
    return response.data.data;
  } catch (error) {
    console.error("Error deleting user:", error);
    throw error;
  }
};

const getVehicalMaster = async () => {
  let getVehicalMaster = await axios.get(`${url}admin/ratecards`);
  // console.log(getVehicalMaster.data.data)
  return await getVehicalMaster.data.data;
};
const getRecord = async () => {
  let getRecord = await axios.post(`${url}admin/getRecord`);
  // console.log(getRecord.data.data)
  return await getRecord.data.data;
};
const updateRecord = async () => {
  let updateRecord = await axios.post(`${url}admin/updateRecord`);
  // console.log(UpdateRecord.data.data)
  return await updateRecord.data.data;
};

const schoolData = async () => {
  let schoolData = await axios.get(`${url}admin/api/schoolData`);
  // console.log(schoolData.data.data)
  // console.log("Tester");
  return await schoolData.data.data;
};

const AddRequest = async () => {
  let AddRequest = await axios.post(`${url}admin/api/AddRequest`);
  // console.log(AddRequest.data.data)
  // console.log("Tester");
  return await AddRequest.data.data;
};
const getInsurance = async () => {
  let getInsurance = await axios.post(`${url}admin/api/getInsurance`);
  // console.log(getInsurance.data.data)
  // console.log("Tester");
  return await getInsurance.data.data;
};

const getAllBooking = async () => {
  try {
    const response = await axios.get(`${url}admin/api/getAllBooking`);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching all bookings:", error);
    throw error;
  }
};

// Function to update bank information
const updateBank = async (id, bankName, accountNumber, IFSCCode, name) => {
  try {
    const response = await axios.put(`${url}admin/api/updateBank/${id}`, {
      bankName,
      accountNumber,
      IFSCCode,
      name,
    });
    return response.data.data;
  } catch (error) {
    console.error("Error updating bank information:", error);
    throw error;
  }
};

const updateVehicle = async (
  id,
  make,
  type,
  puc,
  carHealthCard,
  insurance,
  model,
  color,
  numberOfSeat,
  numberPlate,
  registrationNumber
) => {
  try {
    const response = await axios.put(`${url}admin/api/updateVehicle/${id}`, {
      make,
      type,
      puc,
      carHealthCard,
      insurance,
      model,
      color,
      numberOfSeat,
      numberPlate,
      registrationNumber,
    });
    return response.data.data;
  } catch (error) {
    console.error("Error updating vehicle:", error);
    throw error;
  }
};

const services = {
  getAllDriver,
  getAllUsers,
  ApprovedRequest,
  RejectedRequest,
  ApprovedRequestUser,
  RejectedRequestUser,
  getVehicalMaster,
  getRecord,
  updateRecord,
  schoolData,
  AddRequest,
  getInsurance,
  blockUser,
  deleteUser,
  blockDriver, // Add new function here
  deleteDriver,
  getAllBooking,
  updateBank,
  updateVehicle,
};

export default services;
