import React, { useEffect, useState, useRef } from "react";
import { IoReceiptOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import AOS from "aos";
import "aos/dist/aos.css";
import { AiOutlinePlus } from "react-icons/ai";
import { IoIosArrowUp } from "react-icons/io";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Modal from "react-modal";
import axios from "axios";
const DrillDown = ({ closeDrill, driverId }) => {
  const [drillDownDriver, setDrillDownDriver] = useState([]);
  // const { drillDownDriver } = useSelector((state) => state.panelSlice);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [receiptData, setReceiptData] = useState(null);
  const tableRef = useRef();

  const url = "https://api.zigcabio.com/";
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${url}admin/api/getAllBooking/65edf25f7c7ed386e9e93e1c`
        );
        const data = response.data.data.map((booking, index) => {
          const pickupLat = booking.pickupLat;
          const pickupLong = booking.pickupLong;
          const dropLat = booking.dropLat;
          const dropLong = booking.dropLong;

          // Function to calculate the distance between two coordinates
          const calculateDistance = (lat1, lon1, lat2, lon2) => {
            const toRad = (value) => (value * Math.PI) / 180;
            const R = 6371; // Radius of the Earth in km
            const dLat = toRad(lat2 - lat1);
            const dLon = toRad(lon2 - lon1);
            const a =
              Math.sin(dLat / 2) * Math.sin(dLat / 2) +
              Math.cos(toRad(lat1)) *
                Math.cos(toRad(lat2)) *
                Math.sin(dLon / 2) *
                Math.sin(dLon / 2);
            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            return R * c; // Distance in km
          };

          const totalKMs = calculateDistance(
            pickupLat,
            pickupLong,
            dropLat,
            dropLong
          );

          return {
            SNo: index + 1,
            RiderName: booking.driverId.fullname,
            PickupLocTime: booking.pickupLocationName,
            DropLocationTime: booking.dropLocationName,
            TotalKMs: totalKMs.toFixed(2), // Limit to 2 decimal places
            TotalAmt: booking.amount,
            ModeOfPay:
              booking.paymentMode === "0" ? "Cash" : booking.paymentMode,
            Receipt: "JAIPUR001",
          };
        });
        setDrillDownDriver(data);
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    AOS.init();
  }, []);

  const openModal = (data) => {
    setReceiptData(data);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setReceiptData(null);
  };

  const generatePDF = () => {
    const input = tableRef.current;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "PNG", 10, 10);
      pdf.save("receipt.pdf");
    });
  };

  return (
    <div>
      <div className="bg-white">
        <div className="flex justify-between">
          <IoIosArrowUp
            onClick={() => closeDrill()}
            title="Close History"
            className="text-3xl text-[#8c7fe6] cursor-pointer ml-5 mt-2"
          />
        </div>
        <div className="w-full flex flex-col items-center h-[300px] overflow-y-auto drillMain border-collapse">
          <div className="drillHeaders bg-[white] w-[95%] font-semibold flex justify-around sticky top-0">
            <div className="w-[5%] text-center l t b r">S.No</div>
            <div className="w-[15%] t b r">RiderName</div>
            <div className="w-[20%] t b r">Pickup Location & Time</div>
            <div className="w-[20%] t b r">Drop Location & Time</div>
            <div className="w-[10%] t b r">Total KMs</div>
            <div className="w-[10%] t b r">Total Amt</div>
            <div className="w-[10%] t b r">Mode Of Pay</div>
            <div className="w-[10%] t b r">Receipt</div>
          </div>
          {drillDownDriver.map((item, index) => (
            <div
              key={index}
              className="drillDataRow flex justify-around w-[95%]"
            >
              <div className="justify-center w-[5%] l t r">{item.SNo}</div>
              <div className="w-[15%] t r">{item.RiderName}</div>
              <div className="w-[20%] t r">{item.PickupLocTime}</div>
              <div className="w-[20%] t r">{item.DropLocationTime}</div>
              <div className="justify-center w-[10%] t r">{item.TotalKMs}</div>
              <div className="justify-center w-[10%] t r">{item.TotalAmt}</div>
              <div className="justify-center w-[10%] t r">{item.ModeOfPay}</div>
              <div className="w-[10%] flex justify-center items-center font t r">
                <IoReceiptOutline
                  className="text-[#8c7fe6] text-xl receipt cursor-pointer"
                  onClick={() => openModal(item)}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Receipt Modal"
        ariaHideApp={false}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "40%",
            padding: "20px",
            borderRadius: "10px",
            border: "1px solid #ddd",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            zIndex: 1000, // Add this line
          },
          overlay: {
            zIndex: 999, // Add this line to ensure the overlay is also on top
          },
        }}
      >
        <button
          onClick={closeModal}
          className="relative top-[20px] cursor-pointer float-end right-[30px] hover:bg-red-300 z-10 transform rotate-45 text-2xl p-1 bg-[#8C7FE6] rounded-2xl text-[#E02121]"
        >
          <AiOutlinePlus />
        </button>
        <div ref={tableRef} className="p-4 bg-gray-100 rounded-lg">
          {receiptData && (
            <div className="bg-white shadow-lg rounded-lg border border-gray-200 p-6">
              <div className="text-center mb-4">
                <h2 className="text-xl font-bold">ZigCabio</h2>
                <p className="text-gray-500">{receiptData.Date}</p>
              </div>
              <div className="border-t border-gray-300 py-2">
                <p className="text-gray-700">
                  <strong>Pickup:</strong> {receiptData.PickupLocTime}
                </p>
                <p className="text-gray-700">
                  <strong>Dropoff:</strong> {receiptData.DropLocationTime}
                </p>
              </div>
              <div className="my-4">
                <p className="text-gray-700">
                  <strong>ZigCabio: </strong> {receiptData.TotalKMs} kilometres
                  | {receiptData.Duration} min(s)
                </p>
                <p className="text-gray-700">
                  <strong>Date:</strong> {receiptData.Date}
                </p>
              </div>
              <div className="border-t border-gray-300 py-2">
                <p className="text-gray-700">
                  <strong>Total: </strong>
                  {receiptData.TotalAmt}
                </p>
                <p className="text-gray-700">
                  Trip charge: ₹{receiptData.TripCharge}
                </p>
                <p className="text-gray-700">
                  Promotion: -₹{receiptData.Promotion}
                </p>
              </div>
              <div className="border-t border-gray-300 py-2">
                <p className="text-gray-700">
                  <strong>Payments: </strong>
                  {receiptData.TotalAmt}
                </p>
                <p className="text-gray-700">
                  The total of {receiptData.TotalAmt} has a GST of Rs
                  {receiptData.GST} included.
                </p>
              </div>
              <div className="border-t border-gray-300 py-2">
                <p className="text-gray-700">
                  You ride with <strong>{receiptData.DriverName}</strong>
                </p>
                <p className="text-gray-700">
                  License Plate: {receiptData.LicensePlate}
                </p>
              </div>
              <div className="border-t border-gray-300 py-2 text-center">
                <a
                  href={receiptData.TripLink}
                  className="text-blue-500 underline"
                >
                  Visit the trip page for more information
                </a>
              </div>
            </div>
          )}
        </div>
        <button
          onClick={generatePDF}
          className="mt-4 block mx-auto px-4 py-2 bg-[#8c7fe6] text-white rounded-lg"
        >
          Download Bill Copy
        </button>
      </Modal>
    </div>
  );
};

export default DrillDown;
