import React from "react";
import { AiOutlinePlus } from "react-icons/ai";
// import { CrossIcon } from "some-icon-library"; // Make sure to replace this with your actual icon import

const ConfirmationModal = ({ text, handleAction, handleClose, actionText }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-90">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold text-gray-900">
            Confirm Action
          </h2>
          <AiOutlinePlus
            onClick={handleClose}
            className="relative top-[2px] cursor-pointer float-end right-[5px] hover:bg-red-300 z-10 transform rotate-45 text-2xl p-1 bg-[#8C7FE6] rounded-2xl text-[#E02121]"
          />
        </div>
        <p className="text-gray-700 mb-10">{text}</p>
        <p className="relative" style={{ top: "-25px" }}>
          {actionText}
        </p>
        <div className="flex justify-end space-x-3">
          <button
            onClick={handleClose}
            className="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300"
          >
            Cancel
          </button>
          <button
            onClick={handleAction}
            className="px-4 py-2 bg-[#8C7FE6] text-white rounded hover:bg-[#93a4e9]"
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
