import React from "react";

const BillCopy = () => {
  return (
    <div className="max-w-md mx-auto p-4 bg-white shadow-md rounded-lg">
      <div className="text-center mb-4">
        <h2 className="text-xl font-bold">Uber</h2>
        <p className="text-gray-500">25/07/2024 18:05</p>
      </div>

      <div className="border-t border-gray-300 py-2">
        <div className="text-gray-700">
          <p>
            <strong>Pickup:</strong> 18:50 | 5, Scheme 134, Nipania, Jhalariya,
            Indore, Madhya Pradesh 452016, India
          </p>
          <p>
            <strong>Dropoff:</strong> 19:03 | 13, Scheme No 94c, Indore, Madhya
            Pradesh 452010, India
          </p>
        </div>

        <div className="my-4">
          <p className="text-gray-700">
            <strong>Uber Go</strong> 3.20 kilometres | 12 min(s)
          </p>
          <p className="text-gray-700">
            <strong>Date:</strong> 21 July 2024
          </p>
        </div>

        <div className="border-t border-gray-300 py-2">
          <p className="text-gray-700">
            <strong>Total:</strong> ₹97.66
          </p>
          <p className="text-gray-700">Trip charge ₹105.01</p>
          <p className="text-gray-700">Promotion -₹7.35</p>
        </div>

        <div className="border-t border-gray-300 py-2">
          <p className="text-gray-700">
            <strong>Payments:</strong> ₹97.66
          </p>
          <p className="text-gray-700">
            The total of ₹97.66 has a GST of ₹5.04 included.
          </p>
        </div>

        <div className="border-t border-gray-300 py-2">
          <p className="text-gray-700">
            You rode with <strong>DHIRENDRA</strong>
          </p>
          <p className="text-gray-700">License Plate: MP09DN0357</p>
        </div>

        <div className="border-t border-gray-300 py-2 text-center">
          <a
            href="https://riders.uber.com/trips/671a83ba-cdee-49f7-89e5-a6e6d1b9c893"
            className="text-blue-500 underline"
          >
            Visit the trip page for more information
          </a>
        </div>
      </div>
    </div>
  );
};

export default BillCopy;
