import reducerFunction from "./extraReducersFunction";
const {
  getAllDriver,
  getAllUsers,
  ApprovedRequest,
  RejectedRequest,
  ApprovedRequestUser,
  RejectedRequestUser,
  blockUser,
  deleteUser,
  blockDriver,
  deleteDriver,
  getVehicalMaster,
  getRecord,
  updateRecord,
  schoolData,
  AddRequest,
  getInsurance,
  getAllBooking,
  updateBank,
  updateVehicle,
} = reducerFunction;
export const extraReducers = (builder) => {
  builder
    .addCase(getAllDriver.pending, (state, action) => {
      return {
        ...state,
        isLoading: true,
        message: "",
      };
    })
    .addCase(getAllDriver.fulfilled, (state, action) => {
      return {
        ...state,
        isLoading: false,
        getAllDriver: action.payload,
        isSucces: true,
        message: "",
      };
    })
    .addCase(getAllDriver.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload,
      };
    })
    .addCase(getAllUsers.pending, (state, action) => {
      return {
        ...state,
        isLoading: true,
        message: "",
      };
    })
    .addCase(getAllUsers.fulfilled, (state, action) => {
      return {
        ...state,
        isLoading: false,
        getAllUsers: action.payload,
        isSucces: true,
        message: "",
      };
    })
    .addCase(getAllUsers.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload,
      };
    })
    .addCase(ApprovedRequest.pending, (state, action) => {
      return {
        ...state,
        isLoading: true,
        message: "",
      };
    })
    .addCase(ApprovedRequest.fulfilled, (state, action) => {
      return {
        ...state,
        isLoading: false,
        ApprovedRequest: action.payload,
        isSucces: true,
        message: "",
      };
    })
    .addCase(ApprovedRequest.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload,
      };
    })
    .addCase(RejectedRequest.pending, (state, action) => {
      return {
        ...state,
        isLoading: true,
        message: "",
      };
    })
    .addCase(RejectedRequest.fulfilled, (state, action) => {
      return {
        ...state,
        isLoading: false,
        RejectedRequest: action.payload,
        isSucces: true,
        message: "",
      };
    })
    .addCase(RejectedRequest.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload,
      };
    })
    .addCase(ApprovedRequestUser.pending, (state, action) => {
      return {
        ...state,
        isLoading: true,
        message: "",
      };
    })
    .addCase(ApprovedRequestUser.fulfilled, (state, action) => {
      return {
        ...state,
        isLoading: false,
        ApprovedRequestUser: action.payload,
        isSucces: true,
        message: "",
      };
    })
    .addCase(ApprovedRequestUser.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload,
      };
    })
    .addCase(RejectedRequestUser.pending, (state, action) => {
      return {
        ...state,
        isLoading: true,
        message: "",
      };
    })
    .addCase(RejectedRequestUser.fulfilled, (state, action) => {
      return {
        ...state,
        isLoading: false,
        RejectedRequestUser: action.payload,
        isSucces: true,
        message: "",
      };
    })
    .addCase(RejectedRequestUser.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload,
      };
    })
    .addCase(blockUser.pending, (state) => ({
      ...state,
      isLoading: true,
      message: "",
    }))
    .addCase(blockUser.fulfilled, (state, action) => ({
      ...state,
      isLoading: false,
      blockUser: action.payload,
      isSucces: true,
      message: "",
    }))
    .addCase(blockUser.rejected, (state, action) => ({
      ...state,
      isLoading: false,
      isError: true,
      message: action.payload,
    }))
    .addCase(deleteUser.pending, (state) => ({
      ...state,
      isLoading: true,
      message: "",
    }))
    .addCase(deleteUser.fulfilled, (state, action) => ({
      ...state,
      isLoading: false,
      deleteUser: action.payload,
      isSucces: true,
      message: "",
    }))
    .addCase(deleteUser.rejected, (state, action) => ({
      ...state,
      isLoading: false,
      isError: true,
      message: action.payload,
    }))
    .addCase(getVehicalMaster.pending, (state, action) => {
      return {
        ...state,
        isLoading: true,
        message: "",
      };
    })
    .addCase(blockDriver.pending, (state) => ({
      ...state,
      isLoading: true,
      message: "",
    }))
    .addCase(blockDriver.fulfilled, (state, action) => ({
      ...state,
      isLoading: false,
      blockDriver: action.payload,
      isSucces: true,
      message: "",
    }))
    .addCase(blockDriver.rejected, (state, action) => ({
      ...state,
      isLoading: false,
      isError: true,
      message: action.payload,
    }))

    .addCase(deleteDriver.pending, (state) => ({
      ...state,
      isLoading: true,
      message: "",
    }))
    .addCase(deleteDriver.fulfilled, (state, action) => ({
      ...state,
      isLoading: false,
      deleteDriver: action.payload,
      isSucces: true,
      message: "",
    }))
    .addCase(deleteDriver.rejected, (state, action) => ({
      ...state,
      isLoading: false,
      isError: true,
      message: action.payload,
    }))
    .addCase(getAllBooking.pending, (state, action) => {
      return {
        ...state,
        isLoading: true,
        message: "",
      };
    })
    .addCase(getAllBooking.fulfilled, (state, action) => {
      return {
        ...state,
        isLoading: false,
        getAllBooking: action.payload,
        isSucces: true,
        message: "",
      };
    })
    .addCase(getAllBooking.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload,
      };
    })
    .addCase(getVehicalMaster.fulfilled, (state, action) => {
      return {
        ...state,
        isLoading: false,
        getVehicalMaster: action.payload,
        isSucces: true,
        message: "",
      };
    })
    .addCase(getVehicalMaster.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload,
      };
    })
    .addCase(getRecord.pending, (state, action) => {
      return {
        ...state,
        isLoading: true,
        message: "",
      };
    })
    .addCase(getRecord.fulfilled, (state, action) => {
      return {
        ...state,
        isLoading: false,
        getRecord: action.payload,
        isSucces: true,
        message: "",
      };
    })
    .addCase(getRecord.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload,
      };
    })
    .addCase(updateRecord.pending, (state, action) => {
      return {
        ...state,
        isLoading: true,
        message: "",
      };
    })
    .addCase(updateRecord.fulfilled, (state, action) => {
      return {
        ...state,
        isLoading: false,
        updateRecord: action.payload,
        isSucces: true,
        message: "",
      };
    })
    .addCase(updateRecord.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload,
      };
    })
    .addCase(schoolData.pending, (state, action) => {
      return {
        ...state,
        isLoading: true,
        message: "",
      };
    })
    .addCase(schoolData.fulfilled, (state, action) => {
      return {
        ...state,
        isLoading: false,
        RejectedRequest: action.payload,
        isSucces: true,
        message: "",
      };
    })
    .addCase(schoolData.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload,
      };
    })
    .addCase(AddRequest.pending, (state, action) => {
      return {
        ...state,
        isLoading: true,
        message: "",
      };
    })
    .addCase(AddRequest.fulfilled, (state, action) => {
      return {
        ...state,
        isLoading: false,
        AddRequest: action.payload,
        isSucces: true,
        message: "",
      };
    })
    .addCase(AddRequest.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload,
      };
    })
    .addCase(getInsurance.pending, (state, action) => {
      return {
        ...state,
        isLoading: true,
        message: "",
      };
    })
    .addCase(getInsurance.fulfilled, (state, action) => {
      return {
        ...state,
        isLoading: false,
        AddRequest: action.payload,
        isSucces: true,
        message: "",
      };
    })
    .addCase(getInsurance.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload,
      };
    })
    .addCase(updateBank.pending, (state) => ({
      ...state,
      isLoading: true,
      message: "",
    }))
    .addCase(updateBank.fulfilled, (state, action) => ({
      ...state,
      isLoading: false,
      updateBank: action.payload,
      isSucces: true,
      message: "",
    }))
    .addCase(updateBank.rejected, (state, action) => ({
      ...state,
      isLoading: false,
      isError: true,
      message: action.payload,
    }))
    .addCase(updateVehicle.pending, (state, action) => {
      return {
        ...state,
        isLoading: true,
        message: "",
      };
    })
    .addCase(updateVehicle.fulfilled, (state, action) => {
      return {
        ...state,
        isLoading: false,
        updateVehicle: action.payload,
        isSucces: true,
        message: "",
      };
    })
    .addCase(updateVehicle.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload,
      };
    });
};
