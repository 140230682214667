import React, { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import driverImg from "../../assets/Image/driverImg.png";
import user from "../../assets/Image/User.jpg";
import receiptImg from "../../assets/Image/receipt.jpg";
import NotAvailble from "../../assets/Image/Not_AvailableImg.png";

// const RideDetails = () => {
//     const [receipt, setReceipt] = useState(false);

//     return (
//         <>
//             <div className='flex w-full  justify-around items-start bg-white p-2 driverRideDetail'>
//                 <ul className="flex flex-col justify-start items-start w-[70%]">
//                     <li className="flex justify-between items-start w-full">
//                         <span className='flex gap-3 justify-center items-start'><img src={user} width={20} alt="" /><p className='font-bold'>User Name singh</p></span>
//                     </li>
//                     <li className=""><span className='flex  items-start'><b>From</b> &nbsp;:&nbsp; <p className=''> Location one to Lorem ipsum </p></span></li>
//                     <li className=""><span className='flex items-start'><p className='text-nowrap'><b>To</b>&nbsp;&nbsp; &nbsp; &nbsp;:&nbsp; </p><p className=''> Location one to Loatur dicta odit .</p></span></li>
//                     <div className='flex justify-between w-full'>
//                         <button onClick={() => setReceipt(!receipt)} className='border-2 px-2 bg-[#8c7fe6] hover:border-2 hover:border-gray-500 hover:text-black text-white'>View Reciept</button>
//                         <p className='mr-4'><b>Total Amt</b> : ₹ 100 </p>
//                     </div>
//                     {receipt && <div className="absolute h-[250px] w-[250px]">
//                         <img className='relative top-0 left-[150px]' width={150} src={receiptImg} alt="" />
//                     </div>}
//                 </ul>
//                 <div className="flex flex-col justify-start items-start">
//                     <p><b>Total Km</b> : 10</p>
//                     <p><b>Time</b> : 11:45</p>
//                     <p><b>Time</b> : 12:00</p>
//                 </div>
//             </div>
//         </>
//     )
// }

// const RideDetails = () => {
//     const [ID, setID] = useState(null);
//     let runMarquee = (e) => {
//         setID(e.target.innerText)

//     }
//     let stopMarquee = (e) => {
//         setID("")

//     }

//     const [rideData, setRidedata] = useState([
//         {
//             sNo: 1,
//             riderName: "John Doe",
//             pickUpLocationAndTime: "123 Main St, 10:00 AM",
//             dropLocationAndTime: "456 Elm St, 11:30 AM",
//             status: "Completed",
//             kms: 15,
//             total: "₹20.50",
//             paymentMode: "Credit Card",
//             receipt: "ABC123"
//         },
//         {
//             sNo: 2,
//             riderName: "Jane Smith",
//             pickUpLocationAndTime: "789 Oak St, 12:15 PM",
//             dropLocationAndTime: "987 Pine St, 1:45 PM",
//             status: "Pending",
//             kms: 20,
//             total: "₹25.75",
//             paymentMode: "Cash",
//             receipt: "DEF456"
//         },
//         {
//             sNo: 3,
//             riderName: "Alice Johnson",
//             pickUpLocationAndTime: "222 Birch St, 3:00 PM",
//             dropLocationAndTime: "333 Maple St, 4:30 PM",
//             status: "Cancelled",
//             kms: 10,
//             total: "₹15.25",
//             paymentMode: "PayPal",
//             receipt: "GHI789"
//         },
//         {
//             sNo: 4,
//             riderName: "Bob Williams",
//             pickUpLocationAndTime: "456 Cedar St, 5:45 PM",
//             dropLocationAndTime: "789 Pine St, 7:15 PM",
//             status: "Completed",
//             kms: 18,
//             total: "₹22.00",
//             paymentMode: "Credit Card",
//             receipt: "JKL012"
//         },
//         {
//             sNo: 5,
//             riderName: "Eva Davis",
//             pickUpLocationAndTime: "111 Oak St, 8:30 PM",
//             dropLocationAndTime: "222 Elm St, 9:45 PM",
//             status: "Pending",
//             kms: 12,
//             total: "₹18.50",
//             paymentMode: "Cash",
//             receipt: "MNO345"
//         },
//         {
//             sNo: 6,
//             riderName: "Charlie Brown",
//             pickUpLocationAndTime: "333 Pine St, 11:00 PM",
//             dropLocationAndTime: "444 Cedar St, 12:30 AM",
//             status: "Completed",
//             kms: 25,
//             total: "₹30.75",
//             paymentMode: "PayPal",
//             receipt: "PQR678"
//         },
//         {
//             sNo: 7,
//             riderName: "Grace Taylor",
//             pickUpLocationAndTime: "555 Maple St, 1:15 AM",
//             dropLocationAndTime: "666 Birch St, 2:45 AM",
//             status: "Cancelled",
//             kms: 8,
//             total: "₹12.75",
//             paymentMode: "Credit Card",
//             receipt: "STU901"
//         },
//         // Add more objects as needed
//     ]);
//     return (
//         <>
//             <div className="rideDetails m-2 bg-white">
//                 <table >
//                     <thead>
//                         <tr>
//                             <th style={{ maxWidth: '35px' }}>S.No</th>
//                             <th><p>Rider Name</p></th>
//                             <th><p>PickUp Location <br /> & Time</p></th>
//                             <th><p>Drop Location <br /> & Time</p></th>
//                             <th><p>Status</p></th>
//                             <th style={{ maxWidth: '35px' }}><p>Kms</p></th>
//                             <th><p>Total</p></th>
//                             <th><p>Payment Mode</p></th>
//                             <th><p>Reciept</p></th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {rideData.map((item, index) => (
//                             <tr key={index} onMouseOver={runMarquee} onMouseOut={stopMarquee}>
//                                 <td style={{ maxWidth: '35px' }}>{item.sNo}</td>
//                                 <td >{item.riderName.length > 6 && ID === item.riderName ? <marquee behavior="scroll" scrolldelay="1" scrollamount="2" direction="left">{item.riderName}</marquee> : <p>{item.riderName}</p>}</td>
//                                 <td >{item.pickUpLocationAndTime.length > 6 && ID === item.pickUpLocationAndTime ? <marquee behavior="scroll" scrolldelay="1" scrollamount="2" direction="left">{item.pickUpLocationAndTime}</marquee> : <p>{item.pickUpLocationAndTime}</p>}</td>
//                                 <td >{item.dropLocationAndTime.length > 6 && ID === item.dropLocationAndTime ? <marquee behavior="scroll" scrolldelay="1" scrollamount="2" direction="left">{item.dropLocationAndTime}</marquee> : <p>{item.dropLocationAndTime}</p>}</td>
//                                 <td ><button className={`px-1 py-[3px] text-xs rounded-sm text-white w-[88%] ${item.status === "Completed" ? 'bg-green-500' : item.status === "Cancelled" ? 'bg-red-500' : 'bg-yellow-500'} `}>{item.status}</button></td>
//                                 <td ><p>{item.kms}</p></td>
//                                 <td >{item.total.length > 6 && ID === item.total ? <marquee behavior="scroll" scrolldelay="1" scrollamount="2" direction="left">{item.total}</marquee> : <p>{item.total}</p>}</td>
//                                 <td ><p>{item.paymentMode}</p></td>
//                                 {/* <td>{item.receipt.length > 6 && ID === item.receipt ? <marquee behavior="scroll" scrolldelay="1" scrollamount="2" direction="left">{item.receipt}</marquee> : <p>{item.receipt}</p>}</td> */}
//                                 <td><button className='text-xs rounded-sm bg-[#8c7fe6] text-white px-1 py-[3px]'>Receipt</button></td>
//                             </tr>
//                         ))}
//                     </tbody>
//                 </table>
//             </div>
//         </>
//     )
// }

const DriverDetails = ({ showHideViewFunc, viewData = {}, rideData = [] }) => {
  const [showBill, setShowBill] = useState(false);
  const [selectedRide, setSelectedRide] = useState(null);
  const handleReceiptClick = (sNo) => {
    setSelectedRide(sNo);
    setShowBill(true);
  };
  return (
    <>
      <div className="w-[80%] max-w-[840px] h-[650px] overflow-scroll overflow-x-hidden bg-white pb-7 pt-3 m-auto absolute right-[25%] top-[-35px] rounded-xl driverForm z-20">
        <p
          onClick={() => showHideViewFunc(false)}
          className="sticky top-[13px] cursor-pointer float-end right-[30px] hover:bg-red-300 z-10 transform rotate-45 text-2xl p-1 bg-[#8C7FE6] rounded-2xl text-[#E02121]"
        >
          <AiOutlinePlus />
        </p>
        <div className=" gap-4 mx-4 my-6">
          <h1 className="font-semibold text-[#464650] text-[15px]">
            Driver Details
          </h1>
          {/* <hr className='w-full h-[1px] bg-[#8c7fe6]' /> */}
          <div className="flex flex-col driverDetail bg-[#f4f3fb] rounded-lg my-1 gap-2">
            <div>
              <div className=" rounded-l-lg flex w-full py-4 px-2  justify-center items-start">
                <div className="w-[28%]">
                  <img
                    src={
                      viewData.driverDetails.profileImage
                        ? process.env.REACT_APP_BASE_URL +
                            viewData.driverDetails.profileImage ||
                          `http://157.245.102.51:3002/${viewData.driverDetails.profileImage}`
                        : user
                    }
                    className="h-[180px] w-[180px] rounded-md"
                    alt="ProfileImg"
                  />
                </div>
                <div className="flex flex-col gap-2 w-[36%]">
                  <h2 className="font-semibold text-[#464650] text-[15px]">
                    {viewData.driverDetails.fullname}
                  </h2>
                  <p className="text-gray-500 text-sm">Owner</p>
                  <h2 className="font-semibold text-[#464650] text-[15px]">
                    Phone No
                  </h2>
                  <p className="text-gray-500 text-sm">
                    +91{viewData.driverDetails.phone}
                  </p>
                  <h2 className="font-semibold text-[#464650] gap-2 text-[15px] w-full">
                    E-mail{" "}
                  </h2>
                  <p className="text-gray-500 text-sm gap-2 w-full ">
                    {viewData.driverDetails.email}
                  </p>
                </div>
                <div className="flex flex-col justify-start items-start gap-2 w-[34%]">
                  <h2 className="font-semibold text-[#464650] gap-2 text-[15px] w-full">
                    Address
                  </h2>
                  <p className="text-gray-500 text-sm w-full ">
                    {viewData.driverDetails.address}{" "}
                  </p>
                  <h2 className="font-semibold text-[#464650] gap-2 text-[15px] w-full">
                    City
                  </h2>
                  <p className="text-gray-500 text-sm w-full ">
                    {viewData.driverDetails.city}{" "}
                  </p>
                  <h2 className="font-semibold text-[#464650] gap-2 text-[15px] w-full">
                    Postal Code
                  </h2>
                  <p className="text-gray-500 text-sm w-full ">
                    {viewData.driverDetails.postalCode}{" "}
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="w-[50%] my-1 py-4  flex flex-col gap-2">
                            <div className="flex justify-start w-full">
                                <h2 className='font-semibold text-[#464650] gap-2 text-[15px] w-[30%]'>E-mail </h2>
                                <p className='text-gray-500 text-sm gap-2 w-[70%] '>{viewData.driverDetails.email}</p>
                            </div>
                            <div className="flex  w-full">
                                <h2 className='font-semibold text-[#464650] gap-2 text-[15px] w-[30%]'>Address</h2>
                                <p className='text-gray-500 text-sm w-[70%] '>{viewData.driverDetails.address}</p>
                            </div>
                            <div className='flex '>
                                <div className='w-3/6 flex flex-col items-center justify-center'>
                                    <h2 className='font-semibold text-[#464650] text-[15px] '>Aadhar Card</h2>
                                    <img src={viewData.driverDetails.aadharCard ? process.env.REACT_APP_BASE_URL+viewData.driverDetails.aadharCard || http://157.245.102.51:3002/${viewData.driverDetails.aadharCard} : NotAvailble} className='h-full w-full' alt="ProfileImg" />
                                </div>
                                <div className='w-3/6 flex flex-col items-center justify-center'>
                                    <h2 className='font-semibold text-[#464650] text-[15px] '>Licence</h2>
                                    <img src={viewData.driverDetails.drivingLicense ? process.env.REACT_APP_BASE_URL+viewData.driverDetails.drivingLicense || http://157.245.102.51:3002/${viewData.driverDetails.drivingLicense} : NotAvailble} className='h-full w-full' alt="ProfileImg" />
                                </div>
                            </div>
                            <div className="flex  w-full">
                                <h2 className='font-semibold text-[#464650] text-[15px] w-[30%]'>Licence</h2>
                                <p className='text-gray-500 text-sm w-[70%] '>DL123456</p>
                            </div>
                            <div className="flex justify-start items-start w-full">
                                <h2 className='font-semibold text-[#464650] text-[15px] w-[30%]'>Insurance</h2>
                                <p className='text-gray-500 text-sm w-[70%] '>123-456-789</p>
                            </div>
                        </div> */}
            <div className="w-full ">
              <div className="flex gap-2">
                <div className="w-3/6 flex flex-col items-center justify-center gap-2">
                  <h2 className="font-semibold text-[#464650] text-[15px] ">
                    Aadhar Card
                  </h2>
                  <img
                    src={
                      viewData.driverDetails.aadharCard
                        ? process.env.REACT_APP_BASE_URL +
                            viewData.driverDetails.aadharCard ||
                          `http://157.245.102.51:3002/${viewData.driverDetails.aadharCard}`
                        : NotAvailble
                    }
                    className="h-[300px] w-full rounded-md"
                    alt="ProfileImg"
                  />
                </div>
                <div className="w-3/6 flex flex-col items-center justify-center gap-2">
                  <h2 className="font-semibold text-[#464650] text-[15px] ">
                    Licence
                  </h2>
                  <img
                    src={
                      viewData.driverDetails.drivingLicense
                        ? process.env.REACT_APP_BASE_URL +
                            viewData.driverDetails.drivingLicense ||
                          `http://157.245.102.51:3002/${viewData.driverDetails.drivingLicense}`
                        : NotAvailble
                    }
                    className="h-[300px] w-full rounded-md"
                    alt="ProfileImg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" mx-4 my-6">
          <h1 className="font-semibold text-[#464650] text-[15px] mx-4">
            Vehicle Details
          </h1>
          <div className="flex  justify-between driverDetail rounded-md ">
            <div className="bg-white  flex items-center justify-center w-[48%] rounded-lg ">
              <div className="w-full my-1 p-4  flex flex-col items-center gap-2">
                <div className="flex  w-full">
                  <h2 className="font-semibold text-[#464650] gap-2 text-[15px] w-[50%]">
                    Type
                  </h2>
                  <p className="text-gray-500 text-sm w-[50%] ">
                    {viewData.driverVehicleDetails.type}
                  </p>
                </div>
                <div className="flex justify-start w-full">
                  <h2 className="font-semibold text-[#464650] gap-2 text-[15px] w-[50%]">
                    Registration Number
                  </h2>
                  <p className="text-gray-500 text-sm gap-2 w-[50%]">
                    {viewData.driverVehicleDetails.registrationNumber}
                  </p>
                </div>
                <div className="flex  w-full">
                  <h2 className="font-semibold text-[#464650] text-[15px] w-[50%]">
                    Number Plate
                  </h2>
                  <p className="text-gray-500 text-sm w-[50%] ">
                    {viewData.driverVehicleDetails.numberPlate}
                  </p>
                </div>
                <div className="flex  w-full">
                  <h2 className="font-semibold text-[#464650] gap-2 text-[15px] w-[50%]">
                    Make
                  </h2>
                  <p className="text-gray-500 text-sm w-[50%] ">
                    {viewData.driverVehicleDetails.make}
                  </p>
                </div>
                <div className="flex justify-start items-start w-full">
                  <h2 className="font-semibold text-[#464650] gap-2 text-[15px] w-[50%]">
                    Model
                  </h2>
                  <p className="text-gray-500 text-sm w-[50%] ">
                    {viewData.driverVehicleDetails.model}
                  </p>
                </div>
              </div>
            </div>
            <div className="bg-white  flex items-center justify-center w-[48%] rounded-lg ">
              <div className="w-full my-1 p-4  flex flex-col items-center gap-2">
                <div className="flex justify-start items-start w-full">
                  <h2 className="font-semibold text-[#464650] gap-2 text-[15px] w-[50%]">
                    Vehical Color
                  </h2>
                  <p className="text-gray-500 text-sm w-[50%] ">
                    {viewData.driverVehicleDetails.color}
                  </p>
                </div>
                <div className="flex justify-start w-full">
                  <h2 className="font-semibold text-[#464650] gap-2 text-[15px] w-[50%]">
                    Insurance
                  </h2>
                  <p className="text-gray-500 text-sm w-[50%] ">
                    {viewData.driverVehicleDetails.insurance === 1
                      ? "True"
                      : "False"}
                  </p>
                </div>
                <div className="flex  w-full">
                  <h2 className="font-semibold text-[#464650] gap-2 text-[15px] w-[50%]">
                    No. of Seats
                  </h2>
                  <p className="text-gray-500 text-sm w-[50%] ">
                    {viewData.driverVehicleDetails.numberOfSeat}
                  </p>
                </div>
                <div className="flex  w-full">
                  <h2 className="font-semibold text-[#464650] gap-2 text-[15px] w-[50%]">
                    PUC
                  </h2>
                  <p className="text-gray-500 text-sm w-[50%] ">
                    {viewData.driverVehicleDetails.puc === 1 ? "True" : "False"}
                  </p>
                </div>
                <div className="flex  w-full">
                  <h2 className="font-semibold text-[#464650] gap-2 text-[15px] w-[50%]">
                    Car HealthCard
                  </h2>
                  <p className="text-gray-500 text-sm w-[50%] ">
                    {viewData.driverVehicleDetails.carHealthCard === 1
                      ? "True"
                      : "False"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="px-4 mt-2 flex">
          <h1 className="font-semibold text-[#464650] w-[50%] text-[15px]">
            Bank Details
          </h1>
          <h1 className="font-semibold text-[#464650] w-[50%] px-4 text-[15px]">
            Insurance Details
          </h1>
        </div>
        <div className="flex mx-4 my-1 justify-between">
          <div className="flex items-center justify-center w-[48%] rounded-lg bankDetail bg-[#f4f3fb]">
            <div className="w-full my-1 p-4  flex flex-col items-center gap-2">
              <div className="flex justify-start w-full">
                <h2 className="font-semibold text-[#464650] gap-2 text-[15px] w-[50%]">
                  Bank Name
                </h2>
                <p className="text-gray-500 text-sm gap-2 w-[50%] ">HDFC</p>
              </div>
              <div className="flex  w-full">
                <h2 className="font-semibold text-[#464650] gap-2 text-[15px] w-[50%]">
                  Account No{" "}
                </h2>
                <p className="text-gray-500 text-sm w-[50%] ">8789405465126</p>
              </div>
              <div className="flex  w-full">
                <h2 className="font-semibold text-[#464650] gap-2 text-[15px] w-[50%]">
                  Acc Holder Name{" "}
                </h2>
                <p className="text-gray-500 text-sm w-[50%] ">
                  {viewData.driverDetails.fullname}
                </p>
              </div>
              <div className="flex  w-full">
                <h2 className="font-semibold text-[#464650] text-[15px] w-[50%]">
                  IFSC Code
                </h2>
                <p className="text-gray-500 text-sm w-[50%] ">ABC1234567D5</p>
              </div>
              <div className="flex justify-start items-start w-full">
                <h2 className="font-semibold text-[#464650] text-[15px] w-[50%]">
                  Branch
                </h2>
                <p className="text-gray-500 text-sm w-[50%] ">Main Branch</p>
              </div>
              <div className="flex justify-start items-start w-full">
                <h2 className="font-semibold text-[#464650] text-[15px] w-[50%]">
                  Registered
                </h2>
                <p className="text-gray-500 text-sm w-[50%] ">79854620138</p>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center w-[48%] rounded-lg bankDetail bg-[#f4f3fb]">
            <div className="w-full my-1 p-4  flex flex-col items-center gap-2">
              <div className="flex justify-start w-full">
                <h2 className="font-semibold text-[#464650] gap-2 text-[15px] w-[50%]">
                  Policy Holder
                </h2>
                <p className="text-gray-500 text-sm gap-2 w-[50%] ">
                  {viewData.driverDetails.fullname}
                </p>
              </div>
              <div className="flex  w-full">
                <h2 className="font-semibold text-[#464650] gap-2 text-[15px] w-[50%]">
                  Policy Number{" "}
                </h2>
                <p className="text-gray-500 text-sm w-[50%] ">BI-123456789</p>
              </div>
              <div className="flex  w-full">
                <h2 className="font-semibold text-[#464650] gap-2 text-[15px] w-[50%]">
                  Insurance Company
                </h2>
                <p className="text-gray-500 text-sm w-[50%] ">
                  BikeSure Insurance
                </p>
              </div>
              <div className="flex  w-full">
                <h2 className="font-semibold text-[#464650] text-[15px] w-[50%]">
                  Policy Start Date
                </h2>
                <p className="text-gray-500 text-sm w-[50%] ">
                  January 1, 2024
                </p>
              </div>
              <div className="flex justify-start items-start w-full">
                <h2 className="font-semibold text-[#464650] text-[15px] w-[50%]">
                  Policy End Date
                </h2>
                <p className="text-gray-500 text-sm w-[50%] ">
                  December 31, 2024
                </p>
              </div>
              <div className="flex justify-start items-start w-full">
                <h2 className="font-semibold text-[#464650] text-[15px] w-[50%]">
                  Bike Registration Number
                </h2>
                <p className="text-gray-500 text-sm w-[50%] ">RJ14 CD 5678</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DriverDetails;

{
  /* <div className="flex flex-col justify-between h-full">
                                <h2 className='font-semibold text-[#464650] text-[15px]'>E-mail </h2>
                                <h2 className='font-semibold text-[#464650] text-[15px]'>Address</h2>
                                <h2 className='font-semibold text-[#464650] text-[15px]'>Licence</h2>
                                <h2 className='font-semibold text-[#464650] text-[15px]'>Insurance</h2>
                            </div>
                            <div className="flex flex-col  justify-between h-full">
                                <p className='text-gray-500 text-sm'>Rider@gmail.com</p>
                                <p className='text-gray-500 text-sm'>123, Sample Avenue, Pink City, Jaipur, Rajasthan, India</p>
                                <p className='text-gray-500 text-sm'>DL123456</p>
                                <p className='text-gray-500 text-sm'>123-456-789</p>
                            </div> */
}

{
  /* <ul className='flex w-full justify-around font-bold driverDetail'>
                        <button onClick={(e) => setdetailHeading(!detailHeading)} className={${!detailHeading ? 'text-white bg-[#8c7fe6] px-4 py-2 flex justify-center items-center ' : 'px-4 py-2 flex justify-center items-center'}}>Ride History</button>
                        <button onClick={(e) => setdetailHeading(!detailHeading)} className={${detailHeading ? 'text-white bg-[#8c7fe6] px-4 py-2 flex justify-center items-center ' : 'px-4 py-2 flex justify-center items-center'}}>Driver Details</button>
                    </ul> */
}
{
  /* <div className="driverLeftDiv flex flex-col w-[50%] justify-center items-center rounded-lg">
<div className='flex flex-col m-4 gap-2'>
    <span className='flex'> <p className='font-bold'>Name</p>&emsp;&emsp; : Rider singh Rathore</span>
    <span className='flex'> <p className='font-bold'>Contact</p>&emsp; : +91 789445612</span>
    <span className='flex'> <p className='font-bold'>Email</p>&emsp;&emsp;&ensp;: Rider@gmail.com</span>
    <span className='flex'> <p className='font-bold'>Address</p> &nbsp;&emsp;: 123 Main St, Cityville</span>
    <span className='flex'> <p className='font-bold'>Licence</p>&nbsp;&nbsp;&nbsp; : DL123456</span>
    <span className='flex'> <p className='font-bold'>Insurance</p> : 123-456-789</span>
</div>
</div>
<div className=' w-[50%] flex flex-col justify-start items-center '>
<BankDetails />
</div> */
}
