import React, { useState, useEffect } from "react";
import Heading from "../smallComponents/Heading";
import axios from "axios";
import VehicalForm from "./VehicalForm";

const VehicalMaster = () => {
  const [charge, setCharge] = useState("Day");
  const [carType, setCarType] = useState("Rickshaw");
  const [rates, setRates] = useState({});
  // const [data, setData] = useState([]);

  useEffect(() => {
    // Fetch the initial data from the ratecards API when the component mounts
    axios
      .post("https://api.zigcabio.com/admin/getRecord", {
        selectedValue: carType,
      })
      .then((response) => {
        const ratesData = response.data.data && response.data.data;
        setRates(ratesData);
        // setData(response.data.data);
        // console.log(response.data.data && response.data.data[0] && response.data.data[0]["dayCharge"]["firstKm"])
        // You can set the initial state with fetched data if needed
      })
      .catch((error) => {
        console.error("Error fetching ratecards:", error);
      });
  }, [carType, charge]);

  const handleChargeChange = (selectedCharge) => {
    setCharge(selectedCharge);
  };

  const handleCarTypeChange = (event) => {
    setCarType(event.target.value);
  };

  const handleRateChange = (field, value) => {
    setRates((prevRates) => ({
      ...prevRates,
      [field]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const dataToSubmit = {
      carType,
      charge,
      rates,
    };

    console.log("Data to Submit:", dataToSubmit);

    // Call the updateRecord API to submit the data
    axios
      .post("https://api.zigcabio.com/admin/updateRecord", dataToSubmit)
      .then((response) => {
        console.log("Record updated successfully:", response.data);
        // Reset form fields after successful submission
        // setCharge("Day");
        // setRates({
        //   firstKmRate: "",
        //   secondKmRate: "",
        //   waitingCharge: "",
        //   cancellationCharge: "",
        // });
      })
      .catch((error) => {
        console.error("Error updating record:", error);
      });
  };
  // const rickshawData = data && data.filter((type)=> type.vehicleType === carType);
  return (
    <div>
      <div className="w-full h-[100px] flex items-center px-10">
        <Heading headingText={"Vehicle Master"} />
      </div>
      <form onSubmit={handleSubmit}>
        <div className="w-full px-[41px]">
          <select
            id="carType"
            className="w-[60%] h-14 border-[1px] cursor-pointer rounded-lg p-4 pr-20 text-gray-500 focus:text-black outline-gray-500"
            value={carType}
            onChange={handleCarTypeChange}
          >
            <option value="Rickshaw">Rickshaw</option>
            <option value="Hatchback">Hatchback</option>
            <option value="Sedan">Sedan</option>
            <option value="Suv">Suv</option>
          </select>
        </div>
        {carType && (
          <div>
            {/* <div className="h-[110px]"></div> */}
            <div className="mx-[41px] my-1 px-[40px]">
              <strong>{carType}</strong>
            </div>
            <div className="vehicalType">
              <div className="flex mx-[41px] px-[40px] w-[70%] py-4 rounded-lg bg-white flex-col gap-10">
                <span>
                  <button
                    onClick={() => handleChargeChange("Day")}
                    type="button"
                    className={`w-[150px]   h-[45px] rounded-lg  ${
                      charge === "Day"
                        ? "hover:bg-[#8c7fe6] text-white"
                        : "hover:bg-[#e1e6fa]"
                    } ${charge === "Day" && "bg-[#8c7fe6] text-white   "}`}
                  >
                    Day Charges
                  </button>
                  <button
                    onClick={() => handleChargeChange("Night")}
                    type="button"
                    className={`w-[150px]   h-[45px] rounded-lg  ${
                      charge === "Night"
                        ? "hover:bg-[#8c7fe6] text-white"
                        : "hover:bg-[#e1e6fa]"
                    }  ${charge === "Night" && "bg-[#8c7fe6]   "}`}
                  >
                    Night Charges
                  </button>
                </span>
                <VehicalForm
                  rates={rates}
                  carType={carType}
                  charge={charge}
                  handleRateChange={handleRateChange}
                />
              </div>
            </div>
            <div className="w-[60%] flex justify-center items-center m-4">
              <button
                className="py-2 px-10 rounded-lg   hover:bg-[#c6d0ff] hover:text-black border-black bg-[#8c7fe6] active:bg-transparent text-white"
                type="submit"
              >
                Submit
              </button>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default VehicalMaster;
