import React, { useEffect, useState } from "react";
import Heading from "../smallComponents/Heading";

const HelpAndSupport = () => {
  const [formData, setFormData] = useState({
    phone: "",
    email: "",
    address: "",
    query:"",
  });

  const [contacts, setContacts] = useState([]);
  const [searchTxt, setSearchTxt] = useState("");
  const [filteredContacts, setFilteredContacts] = useState([]);

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setContacts([...contacts, formData]);
    setFormData({
      phone: "",
      email: "",
      address: "",
      query:"",
    });
  };

  const searchFunc = (e) => {
    setSearchTxt(e.target.value.toLowerCase());
  };

  useEffect(() => {
    setFilteredContacts(
      contacts.filter(
        (contact) =>
          contact.phone.toLowerCase().includes(searchTxt) ||
          contact.email.toLowerCase().includes(searchTxt) ||
          contact.address.toLowerCase().includes(searchTxt)||
          contact.query.toLowerCase().includes(searchTxt)
      )
    );
  }, [searchTxt, contacts]);

  return (
    <div className="help-support-container p-8">
      <div className="w-full h-[100px] flex items-center px-10">
        <Heading headingText={"Help and Support"} />
      </div>
      <div className="contact-form mt-6 bg-white w-[70%] mx-20 p-4">
        <form onSubmit={handleSubmit}>
          <div className="flex justify-around formDiv w-full flex-wrap gap-5">
            <div>
              <label htmlFor="phone">Phone</label>
              <input
                type="text"
                id="phone"
                name="phone"
                placeholder="Phone"
                value={formData.phone}
                onChange={handleChange}
                required
                className="border border-gray-300 rounded p-2 w-full"
              />
            </div>
            <div>
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                required
                className="border border-gray-300 rounded p-2 w-full"
              />
            </div>
            <div>
              <label htmlFor="address">Address</label>
              <input
                type="text"
                id="address"
                name="address"
                placeholder="Address"
                value={formData.address}
                onChange={handleChange}
                required
                className="border border-gray-300 rounded p-2 w-full"
              />
            </div>
            <div>
              <label htmlFor="query">Query</label>
              <input
                type="text"
                id="query"
                name="query"
                placeholder="query"
                value={formData.query}
                onChange={handleChange}
                required
                className="border border-gray-300 rounded p-2 w-full"
              />
            </div>
          </div>
          <button
            type="submit"
            className="bg-[#8c7fe6] hover:bg-[#8c89cf] border-2 active:border-black active:text-black text-white font-bold py-2 px-4 rounded mt-4"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default HelpAndSupport;
