import { createAsyncThunk } from "@reduxjs/toolkit";
import services from "./services";

const getAllDriver = createAsyncThunk(
  "GETALLDRIVERS",
  async (formData, thunkApi) => {
    try {
      return await services.getAllDriver();
    } catch (error) {
      let message = error.response ? error.response.statusText : error.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);
const getAllUsers = createAsyncThunk(
  "GETALLUSERS",
  async (formData, thunkApi) => {
    try {
      return await services.getAllUsers();
    } catch (error) {
      let message = error.response ? error.response.statusText : error.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);
const ApprovedRequest = createAsyncThunk(
  "ApprovedRequest",
  async (formData, thunkApi) => {
    try {
      console.log("Approved");
      return await services.ApprovedRequest();
    } catch (error) {
      let message = error.response ? error.response.statusText : error.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);
const RejectedRequest = createAsyncThunk(
  "RejectedRequest",
  async (formData, thunkApi) => {
    try {
      console.log("Rejected");
      return await services.RejectedRequest();
    } catch (error) {
      let message = error.response ? error.response.statusText : error.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);
const ApprovedRequestUser = createAsyncThunk(
  "ApprovedRequestUser",
  async (formData, thunkApi) => {
    try {
      console.log("Approved");
      return await services.ApprovedRequestUser();
    } catch (error) {
      let message = error.response ? error.response.statusText : error.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);
const RejectedRequestUser = createAsyncThunk(
  "RejectedRequestUser",
  async (formData, thunkApi) => {
    try {
      console.log("Rejected");
      return await services.RejectedRequestUser();
    } catch (error) {
      let message = error.response ? error.response.statusText : error.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

const blockDriver = createAsyncThunk("blockDriver", async (id, thunkApi) => {
  try {
    return await services.blockDriver(id);
  } catch (error) {
    let message = error.response ? error.response.statusText : error.message;
    return thunkApi.rejectWithValue(message);
  }
});

const deleteDriver = createAsyncThunk("deleteDriver", async (id, thunkApi) => {
  try {
    return await services.deleteDriver(id);
  } catch (error) {
    let message = error.response ? error.response.statusText : error.message;
    return thunkApi.rejectWithValue(message);
  }
});
const blockUser = createAsyncThunk("blockUser", async (id, thunkApi) => {
  try {
    return await services.blockUser(id);
  } catch (error) {
    let message = error.response ? error.response.statusText : error.message;
    return thunkApi.rejectWithValue(message);
  }
});

const deleteUser = createAsyncThunk("deleteUser", async (id, thunkApi) => {
  try {
    return await services.deleteUser(id);
  } catch (error) {
    let message = error.response ? error.response.statusText : error.message;
    return thunkApi.rejectWithValue(message);
  }
});
const getAllBooking = createAsyncThunk(
  "getAllBooking",
  async (formData, thunkApi) => {
    try {
      return await services.getAllBooking();
    } catch (error) {
      let message = error.response ? error.response.statusText : error.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);
const getVehicalMaster = createAsyncThunk(
  "GETVEHICALMASTER",
  async (formData, thunkApi) => {
    try {
      return await services.getVehicalMaster();
    } catch (error) {
      let message = error.response ? error.response.statusText : error.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);
const getRecord = createAsyncThunk("GETRECORD", async (formData, thunkApi) => {
  try {
    return await services.getRecord();
  } catch (error) {
    let message = error.response ? error.response.statusText : error.message;
    return thunkApi.rejectWithValue(message);
  }
});
const updateRecord = createAsyncThunk("UPDATER", async (formData, thunkApi) => {
  try {
    return await services.updateRecord();
  } catch (error) {
    let message = error.response ? error.response.statusText : error.message;
    return thunkApi.rejectWithValue(message);
  }
});
const schoolData = createAsyncThunk(
  "schoolData",
  async (formData, thunkApi) => {
    try {
      return await services.getAllSchool();
    } catch (error) {
      let message = error.response ? error.response.statusText : error.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);
const AddRequest = createAsyncThunk(
  "AddRequest",
  async (formData, thunkApi) => {
    try {
      return await services.AddRequest();
    } catch (error) {
      let message = error.response ? error.response.statusText : error.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);
const getInsurance = createAsyncThunk(
  "getInsurance",
  async (formData, thunkApi) => {
    try {
      return await services.getInsurance();
    } catch (error) {
      let message = error.response ? error.response.statusText : error.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

const updateBank = createAsyncThunk(
  "updateBank",
  async ({ id, bankName, accountNumber, IFSCCode, name }, thunkApi) => {
    try {
      return await services.updateBank(
        id,
        bankName,
        accountNumber,
        IFSCCode,
        name
      );
    } catch (error) {
      let message = error.response ? error.response.statusText : error.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);
const updateVehicle = createAsyncThunk(
  "updateVehicle",
  async (
    {
      make,
      type,
      puc,
      carHealthCard,
      insurance,
      model,
      color,
      numberOfSeat,
      numberPlate,
      registrationNumber,
    },
    thunkApi
  ) => {
    try {
      return await services.updateVehicle(
        make,
        type,
        puc,
        carHealthCard,
        insurance,
        model,
        color,
        numberOfSeat,
        numberPlate,
        registrationNumber
      );
    } catch (error) {
      let message = error.response ? error.response.statusText : error.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

const reducerFunction = {
  getAllDriver,
  getAllUsers,
  ApprovedRequest,
  RejectedRequest,
  ApprovedRequestUser,
  RejectedRequestUser,
  blockDriver,
  blockUser,
  deleteDriver,
  deleteUser,
  getVehicalMaster,
  getRecord,
  updateRecord,
  schoolData,
  AddRequest,
  getInsurance,
  getAllBooking,
  updateBank,
  updateVehicle,
};

export default reducerFunction;
